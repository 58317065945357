@import 'mixins';
@import 'variables';

:root {
  --app-font: 'Montserrat', sans-serif;
  --login-form-width: 90%;
  --login-layout-width: 400px;
  --input-height: 40px;
  --input-placeholder-color: #A1B3B9;
  --input-bg: #f2f2f2;
}

html {
  &:not(.hydrated) {
    opacity: 0;
    transition: opacity .3s ease;
  }
}

body {
  font-family: var(--app-font);
}

.kriter-logo {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: .3;
  transition: opacity .3s ease;
  &:hover {
    opacity: 1;
  }
}

p {
  margin-bottom: 0;
}

.forget {
  font-size: 13px;
  font-weight: 200;
  margin-top: 40px;
}

h1 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #8e8e8e;
}

kri-button, kri-input {
  display: block;
}

kri-alert, kri-button, kri-input+kri-input {
  margin-top: 20px;
}